@charset "UTF-8";
@font-face {
  font-family: "Futura";
  src: url("fonts/futura-light-bt.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
/* CORES DO SITE #3bbbb3 ffd12a*/
/* FONTS SITE*/
/* TAMANHO DAS FONTES UTILIZADAS*/
/*EXPESSURA DAS FONTES UTILIZADAS*/
/*LAYOUT MARGINS*/
/* ARREDONDAMENTOS*/
/*   QUANTIDADE DE CLASSES GERADAS (pt- pb- pr- pl-....) */
/* TIPOS DE MEDIAS QUERIES*/
/* REFERENCIAS PARA Z-INDEX*/
/***********************************************************
     Adicionando Estilo ao Placeholde em todos os formatos diferentes
     Modelo:
     input{
				@include input-placeholder {
					color: $grey;
				}
		}
 **********************************************************/
/*****************************************
      Definindo a formatão da FONTE
      nome da fonte
      tamanho
      cor
      expessura
      tamanho da linha
**********************************************/
/*******************************************************
    Quando utilizar Pseudo Elementos    ::after   ::before
    Modelo:
    div::after {
		@include pseudo;
		top: -1rem; left: -1rem;
		width: 1rem; height: 1rem;
	}

**************************************************************/
/******************************************

       PARA CENTRALIZAR UM ELEMENTO

 ************************/
/*****************************************
   FACILITADOR PARA A CRIAÇÃO DE Z-INDEX
   MODELOS:
   .site-header {
      z-index: z('site-header');
    }
******************************************/
/********************************************
  DEFININDO COMPORTAMENTOS DIFERENTES CONFORME
  TAMANHO DAS TELAS, DIRETO NA PRÓPRIA CLASSE
  modelo

  .site-header {
    padding: 2rem;
    font-size: 1.8rem;
    @include mq('tablet-wide') {
        padding-top: 4rem;
        font-size: 2.4rem;
    }
  }
 **************************************/
/* FRAMEWORK KHANGOO*/
.wrap {
  flex-wrap: wrap;
}

.pointer {
  cursor: pointer;
}

.hide-mobile {
  display: block;
}
@media only screen and (max-width: 768px) {
  .hide-mobile {
    display: none;
  }
}

.error-msg {
  text-align: left;
  width: 100%;
  padding: 2px 10px;
}

input.error {
  border: 1px solid red;
}

input[type=email] {
  text-transform: lowercase;
}

/* Pagina Principal dos Sorteios */
.raffle-page {
  padding: 5px 0 50px 0;
}
@media only screen and (max-width: 768px) {
  .raffle-page .header_top {
    justify-content: left;
  }
}
.raffle-page .search-numbers {
  position: absolute;
  right: 7px;
  top: 7px;
}
.raffle-page .search-numbers a {
  padding: 10px;
}
.raffle-page .headline p {
  max-width: 600px;
}
.raffle-page .paulo-car {
  padding: 10px;
}
.raffle-page .paulo-car img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  max-width: 400px;
}

.raffles {
  flex-wrap: wrap;
  background: antiquewhite;
  padding: 60px 10px 100px 10px;
}
.raffles .card-raffle {
  position: relative;
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 100%;
  height: 254px;
  max-width: 400px;
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  margin: 10px;
}
.raffles .img-contest {
  height: 100%;
}
.raffles .img-contest img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.raffles .destack {
  position: absolute;
  padding: 12px 17px 12px 22px;
  top: 0;
  left: -10px;
  border-end-end-radius: 20px;
}
.raffles .price {
  position: absolute;
  padding: 10px 15px;
  bottom: 15px;
  left: 0px;
}
.raffles .info-status {
  position: absolute;
  padding: 2px 5px;
  bottom: 19px;
  right: 15px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
.raffles .info-status p {
  padding: 3px;
}
.raffles .info-prize {
  position: absolute;
  padding: 2px 5px;
  bottom: 5px;
  left: 13px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
}

/* Sessao do Topo da Pagina*/
section.title-top .header_top {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
section.title-top .header_top img {
  width: 25px;
}

section.prize .container {
  padding: 0px 0 120px 0;
}
@media only screen and (max-width: 768px) {
  section.prize .container {
    padding-bottom: 70px;
  }
}
section.prize .header_top {
  padding: 20px 5px;
}
@media only screen and (max-width: 768px) {
  section.prize .header_top {
    justify-content: left;
  }
}
section.prize .search-numbers {
  position: absolute;
  right: 9px;
  top: 15px;
}
section.prize .search-numbers a {
  padding: 10px;
}
section.prize .content {
  padding-top: 30px;
}
@media only screen and (max-width: 768px) {
  section.prize .content {
    padding-top: 7px;
  }
}
section.prize .content .content-group {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
}
section.prize .destack-date {
  position: absolute;
  z-index: 5;
  transform: rotate(-40deg);
  width: 273px;
  padding: 18px;
  top: 14px;
  left: -76px;
}
@media only screen and (max-width: 768px) {
  section.prize .destack-date {
    padding: 11px;
    top: 9px;
    left: -87px;
  }
}
section.prize .destack-date p {
  line-height: 2px;
}
section.prize .card-slider {
  max-width: 650px;
  width: 100%;
  background: transparent;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
section.prize .card-slider .slider-pro {
  position: relative;
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  overflow: hidden;
  border: 3px solid #fe7a02;
}
section.prize .card-slider .info-prize {
  opacity: 0.8;
}
section.prize .group-info-price {
  flex-wrap: wrap;
  flex-basis: 100%;
  max-width: 490px;
  padding: 20px 10px;
  margin: 5px;
  border-end-end-radius: 20px;
  border-end-start-radius: 20px;
  justify-content: flex-start;
}
section.prize .group-info-price .group-value {
  align-items: center;
  flex-basis: 100%;
  justify-content: space-between;
}
section.prize .group-info-price .group-value .group-info-x {
  flex-basis: auto;
  margin-right: 15px;
}
section.prize .group-info-price .group-value .group-info-x p {
  line-height: 45px;
  text-align: justify;
}
@media only screen and (max-width: 768px) {
  section.prize .group-info-price .group-value .group-info-x p {
    line-height: 35px;
  }
}
section.prize .group-info-price .group-value .group-info-y {
  flex-basis: 210px;
  margin-left: 15px;
}
@media only screen and (max-width: 768px) {
  section.prize .group-info-price .group-value .group-info-y {
    flex-basis: 160px;
  }
}
section.prize .group-info-price .group-value .group-info-y p {
  line-height: 35px;
  text-align: left;
}
@media only screen and (max-width: 768px) {
  section.prize .group-info-price .group-value .group-info-y p {
    line-height: 26px;
  }
}
section.prize .group-numbers-sold {
  flex-basis: 100%;
  display: contents;
}
section.prize .group-numbers-sold .container-group-numbers {
  width: 100%;
}
section.prize .group-numbers-sold .bar {
  background: #fefefe;
  height: 20px;
  width: 100%;
}
section.prize .group-numbers-sold .bar .fill {
  display: block;
  width: 0;
  height: 20px;
}
section.prize .group-button {
  margin-top: 10px;
  flex-basis: 100%;
  display: flex;
  justify-content: center;
}
section.prize .group-button a {
  flex-basis: 100%;
}

.text-shadow {
  text-shadow: 0.1em 0.1em #333;
}

.text-gradient {
  display: inline-block;
  color: #ff7b00;
}

@supports ((-webkit-background-clip: text) or (background-clip: text)) {
  .text-gradient {
    background: -webkit-linear-gradient(5deg, #d84806, #ff8231 60%);
    -webkit-background-clip: text !important;
    background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    color: transparent;
  }
}
.stripe {
  height: 8px;
  width: 100%;
  position: relative;
  z-index: 2;
}

.stripe-min {
  height: 2px;
  width: 100%;
  position: relative;
  z-index: 2;
}

section.group-video {
  padding: 50px 10px 50px 10px;
}
section.group-video .content {
  padding: 70px 10px;
  flex-wrap: wrap;
}
section.group-video .content .info-video {
  flex-basis: 100%;
  max-width: 700px;
  padding: 20px 5px;
  position: relative;
}
@media only screen and (max-width: 768px) {
  section.group-video .content .info-video p {
    padding-left: 50px;
  }
}
section.group-video .content .up-arrow {
  position: absolute;
  top: -100px;
  width: 100px;
  left: -106px;
}
@media only screen and (max-width: 768px) {
  section.group-video .content .up-arrow {
    left: -15px;
  }
}
section.group-video .border-video {
  border: #fff 10px solid;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  width: 700px;
  height: 400px;
}
section.group-video .border-video iframe {
  width: 100%;
  height: 100%;
}

section.operation .group-operation {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 43px 3px;
}
section.operation .group-operation .container {
  width: 200px;
  margin-left: -20px;
}
section.operation .big {
  font-size: 8em;
  opacity: 0.4;
}
@media only screen and (max-width: 400px) {
  section.operation .big {
    font-size: 7em;
  }
}

section.winners {
  padding: 80px 10px;
}
section.winners .flex-center {
  flex-wrap: wrap;
}
section.winners .flex-center header {
  flex-basis: 100%;
}
section.winners .content {
  flex-basis: 100%;
  max-width: 600px;
}
section.winners .card-winners a {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  overflow: hidden;
  position: relative;
  max-height: 200px;
}
@media only screen and (max-width: 768px) {
  section.winners .card-winners a {
    max-height: 100%;
  }
}
section.winners .card-winners a .destack {
  position: absolute;
  z-index: 5;
  transform: rotate(40deg);
  width: 228px;
  padding: 13px 0 18px 0;
  top: 14px;
  right: -65px;
}
@media only screen and (max-width: 768px) {
  section.winners .card-winners a .destack {
    padding: 11px;
    top: 28px;
    height: 43px;
  }
}
section.winners .card-winners a .destack p {
  line-height: 3px;
}
section.winners .card-winners a .card-image {
  flex-basis: 30%;
  max-height: 200px;
}
@media only screen and (max-width: 768px) {
  section.winners .card-winners a .card-image {
    flex-basis: 100%;
    max-height: 100%;
    height: 300px;
  }
}
section.winners .card-winners a .card-image img {
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  width: 100%;
}
section.winners .card-winners a .card-info {
  flex-basis: 70%;
  padding: 50px 20px;
}
@media only screen and (max-width: 768px) {
  section.winners .card-winners a .card-info {
    flex-basis: 100%;
    padding: 20px 10px;
  }
}
section.winners .card-winners a .card-info .campo {
  width: 100px;
}

.container-reserve {
  overflow: hidden;
  min-height: 100vh;
  align-items: baseline;
}

section.reserve {
  flex-basis: 100%;
}
section.reserve .group-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 5px;
}
section.reserve .group-circle .circle {
  width: 70px;
  height: 70px;
  font-size: 2em;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 768px) {
  section.reserve .group-circle .circle {
    width: 60px;
    height: 60px;
    font-size: 1.8em;
  }
}
section.reserve .group-circle .strip {
  width: 100px;
  height: 5px;
}
@media only screen and (max-width: 768px) {
  section.reserve .group-circle .strip {
    width: 30px;
  }
}

.float-button {
  position: fixed;
  bottom: 54px;
  z-index: 5;
  min-width: 120px;
}
@media only screen and (max-width: 768px) {
  .float-button {
    position: fixed;
  }
}
.float-button a {
  width: 100%;
  border-radius: 50px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
}

.float-right {
  right: -18px;
}
.float-right a {
  border-start-end-radius: 10px;
  border-end-end-radius: 10px;
}

.float-left {
  left: -18px;
}
.float-left a {
  border-start-start-radius: 10px;
  border-end-start-radius: 10px;
}

.limit-step {
  max-width: 400px;
}

.resume-data {
  flex-basis: 100%;
  padding: 10px;
}

section.step {
  padding: 10px 5px 200px 5px;
  position: relative;
}
section.step .error .message {
  color: white;
}
section.step .error .message a {
  color: white;
  font-weight: 400;
}
section.step .error .message a:link {
  color: inherit;
}

#step-1 header {
  flex-basis: 100%;
}
#step-1 .content {
  flex-basis: 100%;
  justify-content: center;
}
#step-1 .content .group-button a {
  width: 100px;
  border-radius: 50px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
}
#step-1 .content .btn-default {
  width: 70px;
  padding: 14px 30px;
}
#step-1 .content input {
  width: 100%;
  max-width: 100px;
  height: 50px;
}
#step-1 .content .g-1 {
  flex-basis: 100%;
}
#step-1 .content .g-2 {
  flex-basis: 100%;
}
#step-1 .content .g-3 {
  flex-basis: 100%;
}
#step-1 .content .g-4 {
  flex-basis: 100%;
}

#step-2 .warning {
  background: #f8dbaf;
  padding: 1px 3px;
  border-radius: 50px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
}
#step-2 header {
  flex-basis: 100%;
}
#step-2 .content {
  flex-basis: 100%;
  justify-content: center;
}
#step-2 .content .container-input {
  display: flex;
  align-items: center;
  flex-basis: 100%;
  max-width: 400px;
  position: relative;
}
#step-2 .content .container-input input {
  width: 100%;
  padding: 15px 0px 15px 40px;
}
#step-2 .content .container-input span {
  position: absolute;
  top: 13px;
  left: 9px;
  color: gray;
}

#step-3 .content {
  flex-basis: 100%;
  justify-content: center;
}
#step-3 .campo {
  min-width: 115px;
  text-align: left;
}
#step-3 .row {
  flex-basis: 100%;
  align-items: center;
}
#step-3 .btn {
  flex-basis: 100%;
}
#step-3 .btn a {
  width: 100%;
}
#step-3 .obs p {
  text-align: left;
}

#step-4 .content {
  display: contents;
}
#step-4 .content #qrcode-pix {
  width: 223px;
  border-radius: 7px;
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;
}
#step-4 .content #qrcode-pix img {
  width: 100%;
}
#step-4 .content .btn-default {
  padding: 10px 20px;
}
#step-4 .copy-paste {
  width: 90%;
}
#step-4 .copy-paste input {
  padding: 10px 5px;
  width: 100%;
}

section.profile {
  padding: 100px 10px 100px 10px;
  flex-basis: 100%;
}
section.profile article {
  display: flex;
  margin: 0 auto;
  max-width: 900px;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
}
section.profile article .image {
  flex-basis: 30%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
@media only screen and (max-width: 768px) {
  section.profile article .image {
    flex-basis: 100%;
  }
}
section.profile article .image img {
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  flex-basis: 30%;
  max-width: 200px;
}
section.profile article .content {
  flex-basis: 60%;
}
@media only screen and (max-width: 768px) {
  section.profile article .content {
    flex-basis: 100%;
    text-align: center;
  }
  section.profile article .content header {
    padding-top: 30px;
  }
  section.profile article .content .info {
    padding-top: 10px;
  }
}
section.profile article .content p {
  line-height: 25px;
}
@media only screen and (max-width: 768px) {
  section.profile article .content .social ul {
    justify-content: center;
  }
}

.group-payment {
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100%;
  z-index: 5;
}

.card-numbers {
  padding: 5px 10px;
  /* unvisited link */
  /* visited link */
  /* mouse over link */
  /* selected link */
}
.card-numbers .campo {
  width: 120px !important;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.card-numbers .answer {
  width: 230px !important;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.card-numbers .status-contest {
  display: flex;
  justify-content: flex-start;
}
.card-numbers .row {
  flex-basis: 100%;
  align-items: center;
}
.card-numbers .circle {
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  padding: 2px 5px;
}
.card-numbers a:link {
  color: inherit;
}
.card-numbers a:visited {
  color: inherit;
}
.card-numbers a:hover {
  color: inherit;
}
.card-numbers a:active {
  color: inherit;
}

.my-numbers section.step {
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: baseline;
}
.my-numbers section.step .limit-step {
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .my-numbers .header_top {
    justify-content: left;
  }
}
.my-numbers .search-numbers {
  position: absolute;
  right: 9px;
  top: 15px;
}
.my-numbers .search-numbers a {
  padding: 10px;
}
.my-numbers header {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 768px) {
  .my-numbers header {
    justify-content: flex-start;
    display: flex;
  }
}
.my-numbers .content {
  width: 100%;
  flex-basis: 100%;
  justify-content: center;
  word-break: break-word;
}
.my-numbers .container-input {
  display: flex;
  align-items: center;
  flex-basis: 100%;
  position: relative;
}
.my-numbers .container-input input {
  width: 100%;
  padding: 15px 0px 15px 40px;
}
.my-numbers .container-input span {
  position: absolute;
  top: 18px;
  left: 9px;
  color: gray;
}

.banner {
  align-items: center;
  background-image: linear-gradient(#fe7a02, #e79c6a);
}

.bg-rocket {
  background-image: url("https://concurso.cmbbrasil.com.br/themes/web_cmbb/assets/images/foguete_fundo.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: inherit;
  background-size: cover;
}

.bg-text-prize {
  background-image: url("https://concurso.cmbbrasil.com.br/themes/web_cmbb/assets/images/text_prize.png");
  background-repeat: no-repeat;
  background-position: 10px 70px;
  background-attachment: inherit;
  background-size: auto;
}
@media only screen and (max-width: 768px) {
  .bg-text-prize {
    background-size: contain;
  }
}

.bg-wave {
  background-image: url("https://concurso.cmbbrasil.com.br/themes/web_cmbb/assets/images/wave_background.jpg");
  background-repeat: no-repeat;
  background-position: 0px -100px;
  background-attachment: inherit;
  background-size: cover;
}

.bg-points {
  background-image: url("https://concurso.cmbbrasil.com.br/themes/web_cmbb/assets/images/bg-points.png");
  background-color: #212121;
  background-repeat: no-repeat;
  background-attachment: inherit;
  background-size: auto;
}
@media only screen and (max-width: 768px) {
  .bg-points {
    background-size: contain;
  }
}

.bg-happiness {
  background-image: url("https://concurso.cmbbrasil.com.br/themes/web_cmbb/assets/images/felicidade.jpg");
  background-color: #212121;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: contain;
  background-blend-mode: overlay;
}
@media only screen and (max-width: 768px) {
  .bg-happiness {
    background-image: url("https://concurso.cmbbrasil.com.br/themes/web_cmbb/assets/images/felicidade.jpeg");
  }
}

.bg-happiness-big {
  background-image: url("https://concurso.cmbbrasil.com.br/themes/web_cmbb/assets/images/felicidade.jpeg");
  background-color: #212121;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-attachment: fixed;
  background-size: cover;
  background-blend-mode: overlay;
}

.bg-rain-gray {
  background-image: url("https://concurso.cmbbrasil.com.br/themes/web_cmbb/assets/images/background-gray-rain.jpeg");
  background-repeat: no-repeat;
  background-color: #212121;
  background-size: cover;
}

.gausiano {
  backdrop-filter: blur(10px);
  -moz-backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.bg-image-prize {
  background-color: #212121;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  background-blend-mode: luminosity;
}
@media only screen and (max-width: 768px) {
  .bg-image-prize {
    background-image: none !important;
  }
}

/*PLUGIN SLIDER PRO*/
/* Example 3 */
#group-slider .sp-selected-thumbnail {
  border: 4px solid #000;
}

.links {
  text-align: center;
  margin-top: 10px;
}
